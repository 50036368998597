import React from 'react';
import Navbar from './Navbar';
import Header from './Header';
import { makeStyles} from '@material-ui/styles'

const useStyle = makeStyles({
    particlesCanva: {
        position: "absolute",
        opasity: "0.4"
    }
})

const Index = () => {
    
    const classes = useStyle() 
    return (
        <>
            <div>
                <Navbar />
                <Header />
            </div>
        </>
    )
}

export default Index;