import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Avatar, Grid, Box } from '@material-ui/core';
import Typed from "react-typed";
import perfile from '../perfile.jpg';

// CSS STYLES
const useStyles = makeStyles(theme => ({
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        margin: theme.spacing(15)
    },
    title: {
        color: "tomato"
    },
    subtitle2: {
        color: "tan",
        marginButton: "5rem",
        display: "block",
        textAlign: "center"
    },
    typedContainer:{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100vw",
        textAlign: "center",
        zIndex: "1"
    }
}));

const Header = () => {
    const classes = useStyles()
    return (
        <Box className={ classes.typedContainer }>
            <Grid container justify="center">
                <Avatar className={ classes.avatar } src={perfile} alt="Alien 4 Life"/>
            </Grid>

            <Typography className={ classes.title } variant="h4">
                Peter - Eloy
            </Typography>
            <br/>

            <Typography className={ classes.subtitle2 } variant="h4" >
            <Typed
                strings={[ "Front-end", "Back-end", "Full Stack", "Server Managment" ]}
                typeSpeed={40}
                backSpeed={30}
                smartBackspace
                loop
                />
            </Typography><Typography className={ classes.title } variant="subtitle2">
                Site = English /  🗣️ (German, Spanish and English)
            </Typography>
            <br/>

        </Box>
    );
};

export default Header;