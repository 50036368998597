import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import Typed from "react-typed"
import Navbar from "./Navbar"
import { BorderAll } from '@material-ui/icons';

// CSS STYLES
const useStyles = makeStyles(theme => ({
  
    subtitle: {
        color: "white",
        marginButton: "3rem",
        display: "",
        textAlign: "left !important",

    },
    subtitle2: {
        color: "rgb(34, 34, 34)",
        marginButton: ".8em",
        display: "block",
        textAlign: "center",
        padding: ".5em",
    },
    typedContainer:{
        position: "absolute",
        top: "60%",
        left: "65%",
        transform: "translate(-50%, -50%)",
        width: "100vw",
        textAlign: "center",
            
    }
}));


const Tech = () => {
    const classes = useStyles();
    return (
        <>
            <Navbar />
        
            <div style={{backgroundColor: "tan"}}>
                <Typography className={ classes.subtitle2 } variant="h4" >
                    <Typed
                    strings={[ "WebDeveloper", "WebDev"]}
                    typeSpeed={40}
                    backSpeed={30}
                    smartBackspace
                    showCursor={false}
                    />
                </Typography>

                <Typography align="center" variant="h6"> ¡Websites, Platforms and Apps! </Typography>
                
                <Typography align="center" variant="subtitle1"> I'm fit with: </Typography>
                <br></br>
                
            </div>

            <Box className={classes.typedContainer}>


                <Typography className={classes.subtitle} variant="h5">
                    <strong>Windows Server 2016:</strong>
                    <Typed
                        strings={[" Remote Control, Plesk, Server Manager"]}
                        typeSpeed={30}
                        startDelay={2500}
                        showCursor={false}
                        />
                </Typography>

                <Typography className={classes.subtitle} variant="h5">
                    <strong>Linux CentOS Server:</strong>
                    <Typed
                        strings={["WHCpanel, Cpanel, SSH"]}
                        startDelay={5000}
                        typeSpeed={30}
                        showCursor={false}
                        />
                </Typography>

                <Typography className={classes.subtitle} variant="h5">
                    <strong>Framework:</strong>
                    <Typed
                        strings={["ReactJS, Laravel, jQuery"]}
                        startDelay={7000}
                        typeSpeed={30}
                        showCursor={false}
                        />
                </Typography>

                <Typography className={classes.subtitle} variant="h5">
                    <strong>Languajes:</strong>
                    <Typed
                        strings={["HTML, CSS, JS, PHP"]}
                        startDelay={8500}
                        typeSpeed={30}
                        showCursor={false}
                        />
                </Typography>

                <Typography className={classes.subtitle} variant="h5">
                    <strong>Version Control:</strong>
                    <Typed
                        strings={["Git, Github, svn"]}
                        startDelay={9500}
                        typeSpeed={30}
                        showCursor={false}
                        />
                </Typography>

                <Typography className={classes.subtitle} variant="h5">
                    <strong>WordPress:</strong>
                    <Typed
                        strings={["Personalitation, Theme creation, Plugin creation"]}
                        startDelay={10500}
                        typeSpeed={30}
                        showCursor={false}
                        />
                </Typography>

            </Box>

        </>
    )
};

export default Tech;