import React from 'react';
import { makeStyles } from "@material-ui/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core"
import LinkedIn from "@material-ui/icons/LinkedIn"
import Twitter from "@material-ui/icons/Twitter"
import { mergeClasses } from '@material-ui/styles'


const useStyles = makeStyles ({
    root: {
        "& .MuiBottomNavigationAction-root": {
            minWidth: 0,
            maxWidth: 250
        },
        "& .MuiSvgIcon-root": {
            fill: "tan",
            "&:hover": {
                fill: "tomato",
                fontSize: "1.8rem"
            }
        }
    },
});


const Footer = () => {
    const classes = useStyles();
    return (
        <>
            <BottomNavigation width="auto" styled={{ background: "#222" }} >

            <BottomNavigationAction
                className={ classes.root }
                style={{ padding:0 }}
                icon= { <Twitter />}
            />

            <BottomNavigationAction
                className={ classes.root }
                style={{ padding:0 }}
                icon= { <LinkedIn />}
            />

            </BottomNavigation>
            
        </>

    )
};

export default Footer;
