import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import MobileRightMenuSlider from "@material-ui/core/Drawer"
import { AppBar, Toolbar, ListItem, ListItemIcon, ListItemText, Avatar,
    Divider, List, Typography, IconButton, Box } from '@material-ui/core'
import { ArrowBack, AssignmentInd, Home, Apps, ContactMail } from '@material-ui/icons'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import ComputerIcon from '@material-ui/icons/Computer';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import MemoryIcon from '@material-ui/icons/Memory';
import wbspprofile from '../wbspprofile.png';
import CallIcon from '@material-ui/icons/Call'; 
import Footer from "./Footer";

// CSS Styles
const useStyles = makeStyles ( theme => (
    {
        menuSliderContainer: {
            width: "250",
            background: "#511",
            height: "100%"
        },
        avatar: {
            display: "block",
            margin: "0.5rem auto",
            width: theme.spacing(13),
            height: theme.spacing(13),
        },
        listItem: {
            color: "tan"
        }
    }
));

// MENU ITEMS AND ROUT TO MAP
const menuItems = [
    {
        listIcon: <EmojiPeopleIcon />,
        listText: "Welcome",
        listPath: "/"
    },
    {
        listIcon: <MemoryIcon />,
        listText: "Tech",
        listPath: "/tech"
    },
    {
        listIcon: <MenuBookIcon />,
        listText: "Resume",
        listPath: "/resume"
    },
    {
        listIcon: <ImportantDevicesIcon />,
        listText: "Platforms",
        listPath: "/portfolio"
    },
    {
        listIcon: <DeveloperModeIcon />,
        listText: "Developer",
        listPath: "/developer"
    },
    {
        listIcon: <CallIcon />,
        listText: "Contact",
        listPath: "/contactmail"
    },
]


const Navbar = () => {
    const [ state, setState ] = useState({
        right: false
    });

    const toggleSlider = ( slider, open ) => () => {
        setState( { ...state, [slider]:open } );
    };

    const classes = useStyles();

    const sideList = slider => (
    <Box 
    className={ classes.menuSliderContainer } 
    component="div"
    onClick={ toggleSlider ( slider, false ) }>
        <Avatar className={ classes.avatar } src={ wbspprofile } alt="Alien 4 Life" />
        <Divider />
        <List>
            { menuItems.map ( ( lsItem, key ) => (
                <ListItem button key={ key } component={ Link } to={ lsItem.listPath }>
                    <ListItemIcon className={ classes.listItem }> { lsItem.listIcon } </ListItemIcon>
                    <ListItemText className={ classes.listItem } primary={ lsItem.listText }> </ListItemText>
                </ListItem>
                )
            )}
        </List>

        
    </Box>
    );

    return (
        <>
        <Box component="nav">
            <AppBar position="static" style={{ background: "#222" }}>
                <Toolbar>
                    <IconButton onClick={ toggleSlider( "right", true )}>
                        <ArrowBack style={{ color: "tomato" }}/>
                    </IconButton>
                    <Typography variant="h5" style={{ color: "tan" }}> menu</Typography>
                    <MobileRightMenuSlider anchor="left" open={ state.right } onClose={ toggleSlider( "right", false ) }> { sideList( "right" ) }
                        <Footer />
                    </MobileRightMenuSlider>
                </Toolbar>
            </AppBar>
        </Box>
        </>
    );
};

export default Navbar;