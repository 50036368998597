import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Box } from "@material-ui/core";
import Navbar from "./Navbar"

const useStyles = makeStyles(theme => ({
    mainContainer: {
        background: "#233"
    },
    timeLine: {
        position: "relative",
        padding: "1rem",
        margin: "0 auto",
        "&:before":{
            content: "''",
            position: "absolute",
            height: "100%",
            border: "1px solid tan",
            right: "40px",
            top: 0
        },
        "&:after": {
            content: "''",
            display: "table",
            clear: "both"
        },
        [theme.breakpoints.up("md")]:{
            padding: "2rem",
            "&:before":{
                left: "calc(50% - 1px)",
                right: "auto"
            }
        }
    },
    timeLineItem: {
        padding: "1rem",
        borderBottom: "2px solid tan",
        position: "relative",
        margin: "1rem 3rem 1rem 1rem",
        clear: "both",
        "&:after": {
            content:"''",
            position: "absolute"
        },
        "&:before": {
            content: "''",
            position: "absolute",
            right: "-0.625rem",
            top: "calc(50% -5px)",
            borderStyle: "solid",
            borderColor: "tomato tomato transparent transparent",
            borderWidth: "0.625rem",
            transform: "rotate(45deg)"
        },
        [theme.breakpoints.up("md")]: {
            width: "44%",
            margin: "1rem",
            "&:nth-of-type(2n)": {
                float: "right",
                margin: "1rem",
                borderColor: "tan"
            },
            "&:nth-of-type(2n):before": {
                right: "auto",
                left: "-0.625rem",
                borderColor: "transparent transparent tomato tomato"
            }
        }
    },
    timeLineYear: {
        textAlign: "center",
        maxWidth: "9.375rem",
        margin: "0 3rem 0 auto",
        fontSize: "1.8rem",
        background: "tomato",
        color: "white",
        lineHeight: 1,
        padding: "0.5rem 0 1rem",
        "&before": {
            display: "none"
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "center",
            margin: "0 auto",
            "&:nth-of-type(2n)": {
                float: "none",
                margin: "0 auto"
            },
            "&:nth-of-type(2n):before": {
                display: "none"
            }
        }
    },
    heading: {
        color: "tomato",
        padding: "3rem 0",
        textTransform: "uppercase"
    },
    subHeading: {
        color: "white",
        padding: "0",
        textTransform: "uppercase"
    }
}));

const Resume = () => {
    
    const classes = useStyles ()

    return (
        <>
            <Navbar />
            <Box component="header" className={ classes.mainContainer }>
                
                <Typography variant="h4" align="center" className={ classes.heading }>
                  places and companies
                </Typography>
                <Typography variant="h6" align="center" className={ classes.subHeading }>
                  experience summarized
                </Typography>


                <Box component="div" className={ classes.timeLine }>
                    <Typography variant="h2" className={ ` ${ classes.timeLineYear } ${ classes.timeLineItem } ` }>
                        2020
                    </Typography>

                    <Box component="div" className={ classes.timeLineItem }>
                        <Typography variant="h5" align="center" className={ classes.subHeading }>
                        Web Developer
                        </Typography>
            
                        <Typography variant="body1" align="center" style={{ color: "tomato" }}>
                        WebsParadise (Spain)
                        </Typography>
            
                        <Typography variant="subtitle1" align="center" style={{ color: "tan" }}>
                        Website development using PHP, JS (JavaScript), CSS and HTML. 
                        Very good knowledge using pure code but also able to work with diferent CMS (Content Managment Systems) such as WordPress (WP) or GoogleSite and
                        diferent frameworks such as ReactJS.
                        
                        </Typography>
                    </Box>

                    <Typography variant="h2" className={ ` ${ classes.timeLineYear } ${ classes.timeLineItem } ` }>
                        2018
                    </Typography>

                    <Box component="div" className={ classes.timeLineItem }>
                        <Typography variant="h5" align="center" className={ classes.subHeading }>
                        Junior Web Developer
                        </Typography>
            
                        <Typography variant="body1" align="center" style={{ color: "tomato" }}>
                        WebsParadise (Spain)
                        </Typography>
            
                        <Typography variant="subtitle1" align="center" style={{ color: "tan" }}>
                        I have great expertise in creating web sites and applications using a variety of modern technologies.
                        I am also very adequate in maintaining websites and mobile apps as I am an expert in using WordPress, WooCommerce, PHP, JavaScript and HTML/CSS.
                        I always focus on producing high quality and module-oriented SEO-friendly codes.
                        </Typography>
                    </Box>



                    <Typography variant="h2" className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
                        216
                    </Typography>

                    <Box component="div" className={classes.timeLineItem}>
                        <Typography variant="h5" align="center" className={classes.subHeading}>
                        IT Business Analyst
                        </Typography>
            
                        <Typography variant="body1" align="center" style={{ color: "tomato" }}>
                        Agilisys (U.K.)
                        </Typography>
            
                        <Typography variant="subtitle1" align="center" style={{ color: "tan" }}>
                        Gathers and manages requirements from our customers and stakeholders
                        in pre-project stages, and facilitates the evaluation of solution options
                        and recommendations to support the justification and initiation of the project.
                        </Typography>
                    </Box>





                    <Typography variant="h2" className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
                        2015
                    </Typography>

                    <Box component="div" className={classes.timeLineItem}>
                        <Typography variant="h5" align="center" className={classes.subHeading}>
                        
                        </Typography>
            
                        <Typography variant="body1" align="center" style={{ color: "tomato" }}>
                        Universidad de la Laguna
                        </Typography>
            
                        <Typography variant="subtitle1" align="center" style={{ color: "tan" }}>
                        I have a 5-year Bachelor of Science in Economics equivalent to a Bachelor's degree plus a Master's degree. 
                        During the studies we have delved into subjects such as finance, econometrics, mathematics, analytical thinking, macro and microeconomics.
                        Finish the degree with two honors in the subjects: "Economic Thought" and "Concepts and Approaches to Economic Science".
                        </Typography>
                    </Box>



                </Box>
            </Box>           
        </>
    )
}

export default Resume;
