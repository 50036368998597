import React from 'react'
import {makeStyles} from "@material-ui/core/styles" 
import {
    Box,
    Grid,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography
} from "@material-ui/core"
import Navbar from "./Navbar"
import coding1 from '../assets/img/coding1.png'
import code2 from '../assets/img/code2.jpg'
import code3 from '../assets/img/code3.jpg'
import code4 from '../assets/img/code4.jpg'
import code5 from '../assets/img/code5.jpg'
import code6 from '../assets/img/code6.jpg'
import code7 from '../assets/img/code7.jpg'
import code8 from '../assets/img/code8.png'


const useStyles = makeStyles ({
    mainContainer: {
        background: "#233",
        height: "100%"
    },
    cardContainer: {
        maxWidth: 345,
        margin: "5rem auto"
    }
});



const Developer = () => {
    const classes = useStyles();
    return (
        <>
            <Box component="div" className={ classes.mainContainer }>
                <Navbar />
                <Grid container justify="center">

                    {/* Project-1 */}
                    <Grid item xs={12} sm={8} md={6}> 
                        <Card className={ classes.cardContainer }>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt="Alien 4 Life"
                                height="140"
                                image={ coding1 }
                                />

                                <CardContent>
                                    <Typography gutterBottom variant="h5"> TenerifeGolfSpa</Typography>
                                    <Typography  variant="body2" color="textSecondary" component="p"> Website for booking and reserving golf courses, coordinating with the medical centre, the golf providers and the tourist </Typography>
                                </CardContent>
                            </CardActionArea>    
                        </Card>
                    </Grid>


                    {/* Project-2 */}
                    <Grid item xs={12} sm={8} md={6}> 
                        <Card className={ classes.cardContainer }>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt="Alien 4 Life"
                                height="140"
                                image={ code2 }
                                />

                                <CardContent>
                                    <Typography gutterBottom variant="h5"> OvniCanarias</Typography>
                                    <Typography  variant="body2" color="textSecondary" component="p">Re-design, bug fixing. Update of server and PHP3 to PHP6 </Typography>
                                </CardContent>
                            </CardActionArea>    
                        </Card>
                    </Grid>


                    {/* Project-3 */}
                    <Grid item xs={12} sm={8} md={6}> 
                        <Card className={ classes.cardContainer }>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt="Alien 4 Life"
                                height="140"
                                image={ code3 }
                                />

                                <CardContent>
                                    <Typography gutterBottom variant="h5"> VerdaderaCuba </Typography>
                                    <Typography  variant="body2" color="textSecondary" component="p"> Vacational rent booking platform with local excursion provider. Diferent tipes of user: shop owner, house owner, admin and clients. </Typography>
                                </CardContent>
                            </CardActionArea> 
                        </Card>
                    </Grid>


                    {/* Project-4 */}
                    <Grid item xs={12} sm={8} md={6}> 
                        <Card className={ classes.cardContainer }>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt="Alien 4 Life"
                                height="140"
                                image={ code4 }
                                />

                                <CardContent>
                                    <Typography gutterBottom variant="h5"> BananaPalmBay</Typography>
                                    <Typography  variant="body2" color="textSecondary" component="p"> E-commerce for turist activities. Special PHP function to take on repetitive SEO task and automatizate them. </Typography>
                                </CardContent>
                            </CardActionArea>   
                        </Card>
                    </Grid>


                    {/* Project-5 */}
                    <Grid item xs={12} sm={8} md={6}> 
                        <Card className={ classes.cardContainer }>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt="Alien 4 Life"
                                height="140"
                                image={ code5 }
                                />

                                <CardContent>
                                    <Typography gutterBottom variant="h5"> FunBakery</Typography>
                                    <Typography  variant="body2" color="textSecondary" component="p"> Updating from PHP4 to PHP7, Design modernicing (HTML, CSS), integrat special functionalities (PHP), API adjustment to a printing software (json) </Typography>
                                </CardContent>
                            </CardActionArea>    
                        </Card>
                    </Grid>


                    {/* Project-6 */}
                    <Grid item xs={12} sm={8} md={6}> 
                        <Card className={ classes.cardContainer }>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt="Alien 4 Life"
                                height="140"
                                image={ code6 }
                                />

                                <CardContent>
                                    <Typography gutterBottom variant="h5"> Spielserver-Vermitlung  </Typography>
                                    <Typography  variant="body2" color="textSecondary" component="p"> Reverse enginnniering of a WordPress Theme to build it in pure code using HTML, CSS, JavaScript. The PHP original WordPress backend was ignored and we integratied the frontend on .NET stack.</Typography>
                                </CardContent>
                            </CardActionArea>   
                        </Card>
                    </Grid>

                    {/* Project-7 */}
                    <Grid item xs={12} sm={8} md={6}> 
                        <Card className={ classes.cardContainer }>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt="Alien 4 Life"
                                height="140"
                                image={ code7 }
                                />

                                <CardContent>
                                    <Typography gutterBottom variant="h5"> CTE Agora  </Typography>
                                    <Typography  variant="body2" color="textSecondary" component="p"> A WordPress LMS with diferent profiles like teacher, inspector, student,etc... each one with his profile, rights and limitations. (HTML, CSS, PHP, JS) </Typography>
                                </CardContent>
                            </CardActionArea>   
                        </Card>
                    </Grid>


                    {/* Project-7 */}
                    <Grid item xs={12} sm={8} md={6}> 
                        <Card className={ classes.cardContainer }>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt="Alien 4 Life"
                                height="140"
                                image={ code8 }
                                />

                                <CardContent>
                                    <Typography gutterBottom variant="h5"> SociedadAyni </Typography>
                                    <Typography  variant="body2" color="textSecondary" component="p"> A Google Site with all of my researches done during University. Hosted at Google. </Typography>
                                </CardContent>
                            </CardActionArea>   
                        </Card>
                    </Grid>


                </Grid>
            </Box>
        </>
    )
}

export default Developer;
