import  React from 'react';
import { Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import Index from './components/Index.js';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import Tech from './components/Tech';
import Developer from './components/Developer';
import ContactMail from './components/ContactMail';



function App() {

  return (
    <>
      <CssBaseline />
      <Route exact path="/" component={ Index } />
      <Route path="/resume" component={ Resume } />
      <Route path="/portfolio" component={ Portfolio } />
      <Route path="/tech" component={ Tech } /> 
      <Route path="/developer" component={ Developer } />
      <Route path="/contactmail" component={ ContactMail } />
    </>
  );

}


export default App;
