
import React, { Component } from 'react'
import Navbar from "./Navbar"
import '../assets/css/Portfolio.css'

class Portfolio extends Component {
    state = {
        products: [

            {
            id: '6', name: 'PachamamaInca', description: 'UI/UX to combine three different topics: knwoledge, journeys and shopping. Blog, booking system, e-commerce (Aliexpress integration) automatizated processes. Migration from to new Linux host', techs:['PHP', 'JS', 'HTML', 'CSS', 'WordPress', 'MySQL'], extra: ['Landing pages optimieren WordPress Blocks gestallten – HTML, CSS, PHP', 'Template Seiten gestalten (HTML, CSS, PHP)'], company: 'Travel Agency'
            },
            {
            id: '7', name: 'FoodApp',  description: 'Formulae for Landing page, redesign Landing page, Konzepierung für Tischreservierung, Backend für Tischreservierung.', techs: ['PHP', 'JS', 'HTML', 'CSS', 'Bootstrap', 'Laravel'], company: 'Food-Delivery Managment (CRM & CMS)'
            },
            {
            id: '8',  name: 'RentIT', description: 'WS2012 to WS2016 migration, User and Admin frontend -Full responsive, reverse engineering Bootstrap to local server to use independent from update cycles. Landing page.', techs: ['PHP', 'JS', 'ReactJS', 'HTML', 'CSS', 'Bootstrap', 'XML'], company: 'Vacacional Renting Managment Platform'
            },
            {
            id: '9',  name: 'PhotoMenu', description: 'Setup development enviroment, Full responsive Frontend, Premium Admin Panel Frontend, Premium Admin Panel Backend, Covid-tracker integration, Covid-tracker Admin Panel.', techs: [ 'PHP', 'JS', 'HTML', 'CSS', 'Bootstrap' ], company: 'Digital Restaurant Menu'
            },
        ]
    }

    render (){
        return (      
            <div className="body">
                <Navbar />
                <div className="wbspTitle">
                    <h2>Platform Projects</h2>
                    <p>My ambition is to stay on head of the State of the Art</p>
                </div>
                <div className="card-list">
                    {this.state.products.map((product) => (                    
                        <div className="card" key={ product.id } >

                            <div className="card-header"><p> {product.company} </p><h2> { product.name } </h2></div>

                            <div class="author-name">
                                <div class="author-name-prefix">Description:</div>
                                <br></br>
                                { product.description }
                            </div>
                                                                                                            
                            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                                {product.techs.map(( tech ) => (
                                    <div className="tags" key={`tech-${product.id}`} ><a> { tech } </a></div>
                                ))}
                            </div>

                        </div>
                    ))}
                </div> 
            </div>                
        )
    }
}

export default Portfolio;