import React from 'react'
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { TextField, Typography, Button, Grid, Box } from "@material-ui/core"
import SendIcon from "@material-ui/icons/Send"
import Navbar from "./Navbar"

const useStyles = makeStyles(theme=>({
    form: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute"
    },
    button: {
        marginTop: "1rem",
        color: "tomato",
        borderColor: "tomato"
    }
}));




const InputField = withStyles ({
    root: {
        "& label.Mui-focused":{
            color: "tomato"
        },
        "& label": {
            color: "tan"
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "tan"
            },
            "&:hover fieldset": {
                borderColor: "tan"
            },
            "& .Mui-focused fieldset": {
                borderColor: "tan"
            }
        }
    },
})(TextField); 


const ContactMail = () => {

    const classes = useStyles ()

    return (
        <>
        <Box component="div" style={{ background: "#233" , height: "100vh" }}>
            <Navbar />
            <Grid container justify="center">
            <Box component="form" className={classes.form}>
                <Typography variant="h4"
                    style={{
                        color:"tomato",
                        textAlign: "center",
                        textTransform: "upercase"
                    }}>
                        whatsapp
                </Typography>

                <Typography variant="h6"
                    style={{
                        color:"tomato",
                        textAlign: "center",
                        textTransform: "upercase"
                    }}>
                        - underconstruction -
                </Typography>

                <InputField 
                fullWidth={true}         
                label="Name"
                variant="outlined"
                inputProps={{ style:{ color: "white" } }}
                margin="dense"
                size="medium"
                />

                <br></br>

                <InputField 
                fullWidth={true}         
                label="Company"
                variant="outlined"
                inputProps={{ style:{ color: "white" } }}
                margin="dense"
                size="medium"
                />


                <InputField 
                fullWidth={true}         
                label="Message"
                variant="outlined"
                inputProps={{ style:{ color: "white" } }}
                margin="dense"
                size="medium"
                />

                <Button className={ classes.button } variant="outlined" fullWidth="true" endIcon={<SendIcon />}>
                    Contact Now
                </Button>

            </Box>
            </Grid>
        </Box>
        </>
    )
};

export default ContactMail;
